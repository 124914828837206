<template>
	<div>
		<contactus v-if="showContactUs"></contactus>
		<div class="metting-bottom">
			<div style="padding-top: 20px;width: 80%;margin: auto;">
			</div>
			<div class="metting-bottom-1">
				<div class="metting-bottom-2" style="margin-right: 200px;">
					<div>
						<el-image :src="require('@/assets/img/logo.png')" style="width: 211px;"></el-image>
					</div>
					<div class="metting-bottom-2-1">
						国际管理科学与工程技术协会（International Association of Management Science and Engineering Technology,
						简称IAMSET），发源于2010年，2011年在香港政府注册成立（注册号：63604471），存续为合法运营的专业机构。
					</div>
				</div>
				<div class="metting-bottom-2">
					<p style="font-size: 18px;font-weight: bold;color: #262626; padding-bottom: 10px;margin-top:10px">
						服务项目
					</p>
					<p>
						<router-link @click.native="scrollToTop" to="/journalSci">期刊搜索</router-link>
					</p>
					<p>
						<router-link @click.native="scrollToTop" to="/meeting">学术会议</router-link>
					</p>
					<p>
						<router-link @click.native="scrollToTop" to="/translate">论文翻译</router-link>
					</p>
					<p>
						<router-link @click.native="scrollToTop" to="/polishing">论文润色</router-link>
					</p>
					<p>
						<router-link @click.native="scrollToTop" to="/selective">选刊服务</router-link>
					</p>
					<p>
						<router-link @click.native="scrollToTop" to="/difference">查重服务</router-link>
					</p>
					<p>
						<router-link @click.native="scrollToTop" to="/retrieval">检索证明</router-link>
					</p>
				</div>
				<div class="metting-bottom-2">
					<p style="font-size: 18px;font-weight: bold; color: #262626; padding-bottom: 10px;margin-top:10px">
						关于IAMSET
					</p>
					<p>
						<router-link @click.native="scrollToTop" to="/about">关于我们</router-link>
					</p>
					<p>
						<router-link @click.native="scrollToTop" to="/news?tab=资源下载">资源下载</router-link>
					</p>
					<p>
						<router-link @click.native="scrollToTop('question')" to="/about">常见问题</router-link>
					</p>
					<p>
						<el-popover placement="top" trigger="hover">
							<span slot="reference">商务合作</span>
							<div class="show_warp_image_box">
								<img :src="require('@/assets/img/xueshuxiaobian.jpg')" alt="" class="show_warp_image_">
							</div>
						</el-popover>
					</p>
					<p>
						<a href="https://www.zhaopin.com/companydetail/CZL1465626340.htm?refcode=4019&srccode=401901&preactionid=d77c83b7-61cb-4168-8c8a-2bedeac42ac9"
							target="_blank">加入我们</a>
					</p>
				</div>
				<div class="metting-bottom-2">
					<p
						style="margin-right: 10px; color: #262626; padding-bottom: 10px;margin-top: 6px; font-size: 18px;font-weight: bold;">
						<img src="../../assets/img/about/index_phone.png" alt=""> 投稿热线
					</p>
					<p>
						<span>0371-63360580</span>
					</p>
					<p>
						<span>0371-61319290</span>
					</p>
				</div>
			</div>

			<div class="metting-bottom-5">
				<div class="metting-bottom-5_1">
					<warp></warp>
				</div>
			</div>

			<div class="metting-bottom-5">
				<div class="metting-bottom-5_1 m5_1_hr">
				</div>
			</div>

			<div class="metting-bottom-4">

				<p class="mb_4_41">Copyright © 2010-2025 IAMSET艾慕赛特 版权所有 All Rights Reserved. <a
						style="color:#262626;text-decoration: none;margin-left: 6px;"
						href="http://beian.miit.gov.cn">豫ICP备2024092800号-1</a>
					<span class="mb_4_42"><span class="mb_4_43" @click="goToPage('privacy')">隐私政策</span><span
							style="margin: 0 4px;">|</span>
						<span class="mb_4_43" @click="goToPage('userAgreement')">用户协议</span> </span>
				</p>

			</div>
		</div>
	</div>
</template>

<script>
import contactus from "./contactus.vue"
import warp from './warp.vue';

export default {
	name: "influenceBottom",
	components: {
		contactus,
		warp
	},
	data() {
		return {
			showContactUs: true
		};
	},
	methods: {
		scrollToTop(id) {
			const top = id == 'question' ? 2460 : 0

			window.scroll({
				top: top,
				left: 0,
				behavior: 'smooth'
			});
		},
		goToPage(page) {
			this.scrollToTop()
			this.$router.push(page)
		},
		checkRouteForContactUs() {
			const hideContactUsRoutes = ['/userAgreement', '/privacy'];
			this.showContactUs = !hideContactUsRoutes.includes(this.$route.path);
		}
	},
	mounted() {
		this.checkRouteForContactUs()
	},
};
</script>
<style scoped lang="scss">
.metting-bottom {
	width: 100%;
	min-width: 1200px;
	color: #262626;
	background-color: white;
	border: 0 none;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	padding: 20px 0 8px 0;
	display: flex;
	flex-direction: column;
	align-items: center
}

.metting-bottom-1 {
	display: flex;
	line-height: 7px;
	width: 1200px;
	min-width: 1200px;
	justify-content: space-between;
}

.metting-bottom-2 {
	.metting-bottom-2-1 {
		max-width: 496px;
		line-height: 2;
		font-size: 14px;
		color: #262626;
		margin-top: 20px;
	}

	img {
		width: 18px;
		height: 18px;
	}
}

.metting-bottom-2 p {
	line-height: 15px;
}

.logo_box {
	display: flex;
	align-items: center;
}

.metting-bottom-2 a {
	color: #262626;
	text-decoration: none;
	font-size: 14px;
	transition: all 0.2s;

	&:hover {
		cursor: pointer;
		color: #1b5faa;
	}
}

.metting-bottom-2 span {
	font-size: 14px;
}

.el-link.el-link--default {
	color: #262626;
}

.elLink:hover {
	color: #add6ff;
}

.metting-bottom-3 {
	display: flex;
}

.metting-bottom-4 {
	min-width: 1200px;
	color: #666666;
	line-height: 7px;
	font-size: 12px;
	margin-top: 30px;
	margin-bottom: 20px;
	text-align: center;

	.mb_4_41 {
		position: relative;

		.mb_4_42 {
			position: absolute;
			right: 0;

			.mb_4_43 {
				cursor: pointer;
				transition: all 0.2s;

				&:hover {
					color: #1b5faa;
				}
			}
		}
	}
}

.metting-bottom-5 {
	width: 100%;
	display: flex;
	justify-content: center;

	.metting-bottom-5_1 {
		margin-top: 30px;
		width: 1200px;
		display: flex;
		justify-content: flex-end;
	}

	.m5_1_hr {
		height: 1px;
		background-color: #e5e5e5;
	}
}
</style>